<template>
  <div>
    <v-row class="justify-end mr-3 pt-3 pb-5">
      <v-btn
        v-if="!editable" 
        class="button-headers"
        outlined
        @click="(allowEdit(true))"
      >
        {{ $t('edit') }}
      </v-btn>
      <v-btn 
        v-if="editable"
        class="button-headers white--text px-7" 
        :color="salesCloudColors.green"
        :disabled="!canSaveChanges"
        @click="saveChanges"
      >
        {{ $t('Save') }}
      </v-btn>
      <v-btn 
        v-if="editable"
        class="button-headers white--text px-7" 
        :color="salesCloudColors.red"
        @click="cancel"
      >
        {{ $t('cancel') }}
      </v-btn>
      <v-btn
        v-if="false"
        class="button-headers" 
        @click="addItem"
        outlined
      >
        <v-icon
          left
          color="blue darken-2"
        >
          mdi-plus
        </v-icon>
        {{ $t('addItem') }}
      </v-btn>
    </v-row>
    <v-row class="justify-space-around text-left mx-2">
      <v-col
        cols="2"
        style="opacity: 35%;"
        class="text-subtitle-2 pr-0"
      >
        {{ $t('quantity') }} 
      </v-col>
      <v-col
        cols="3"
        style="opacity: 35%;"
        class="text-subtitle-2"
      >
        {{ $t('lineItem') }} 
      </v-col>
      <v-col
        cols="2"
        style="opacity: 35%;"
        class="text-subtitle-2 pl-0"
      >
        {{ $t('unitPrice') }} 
      </v-col>
      <v-col
        cols="1"
        style="opacity: 35%;"
        class="text-subtitle-2 pl-0"
      >
        {{ $t('discount') }} 
      </v-col>
      <v-col
        cols="2"
        style="opacity: 35%;"
        class="text-subtitle-2"
      >
        {{ $t('subTotal') }} 
      </v-col>
      <v-col cols="1">
        <!-- Needs to be empty to match columns with LineItemListItem-->
      </v-col>
    </v-row>
    <v-divider class="mx-4 mb-6" />
    <v-list
      class="pt-0 pb-0"
      v-if="Array.isArray(proxyList) && proxyList.length > 0"
      :key="proxyList[0].uuid + listRenderKey"
    >
      <LineItemListItem
        v-for="(lineItem) in proxyList"
        class="mx-2"
        :key="lineItem.uuid"
        :line-item="lineItem"
        :editable="editable"
        :applied-coupon="lineItemCoupon(lineItem)"
      />
    </v-list>
    <v-list v-else>
      <v-list-item>
        <v-list-item-title>{{ $t('noLineItems') }}</v-list-item-title>
      </v-list-item>
    </v-list>


    <v-dialog
      :value="saveChangesDialog"
      max-width="800px"
      scrollable
      persistent
    >
      <v-card>
        <v-card-title>{{ $t('batchProcess') }}</v-card-title>
        <v-card-subtitle>{{ $t('youAreAboutToProcessLengthItems', {itemsLength: this.proxyList.length }) }}</v-card-subtitle>
        <v-card-text
          class="pt-4"
        >
          <v-progress-linear
            :value="progress"
            height="25"
          >
            <strong>{{ Math.ceil(progress) }}%</strong>
          </v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>

import LineItemListItem from './LineItemListItem'

export default {
  name: 'LineItemList',
  props: {
    lineItems: {
      type: Array, 
      default: () => []
    },
		couponCalculation: {
			type: Object, 
			default: null
		},
		coupon: {
			type: Object,
			default: null
		}
  },
  components: {
      LineItemListItem
  },
  data() {
    return {
      proxyList: [],
      editable: false,
      listRenderKey: 0,
      saveChangesDialog: false,
      progress: 0
    }
  },
  mounted() {
    this.resetProxyList()

    // Tracking usage of loading the line item list
    this.$store.dispatch('trackEvent', { eventName: 'Line Item List Loaded' })
  },
  computed: {
    salesCloudColors() {
      return this.$store.state.salesCloudColors
    },
    canSaveChanges() {
		return this.proxyList.some(lineItem => {
			if(typeof lineItem === 'undefined' || lineItem === null) {
				return false
			}
			if(isNaN(lineItem.discountPercent) || typeof lineItem.discountPercent === 'undefined') {
				return false
			}
			return lineItem.discountPercent >= 0 && lineItem.discountPercent <= 100
		})
    }
  },
  methods: {

	lineItemCoupon(lineItem) {
		if(typeof this.couponCalculation === 'undefined' || this.couponCalculation === null) {
			return this.coupon
		}
		if(!Array.isArray(this.couponCalculation.lineItems)) {
			return this.coupon
		}
		const foundCouponCalculationLineItem = this.couponCalculation.lineItems.find(li => li && li.uuid === lineItem.uuid)
		if(!foundCouponCalculationLineItem) {
			return null
		}
		if(foundCouponCalculationLineItem.apply === 'none') {
			return null
		}
		return this.couponCalculation.coupon
	},
    allowEdit(value) {
      this.editable = value

      // Tracking usage of opening the line item edit form
      if(value) {
        this.$store.dispatch('trackEvent', { eventName: 'Line Item Edit Started' })
      }
    },
    addProgress: function () {
        this.progress = this.progress + (1 / ( this.proxyList.length ) * 100)
        if(this.progress === 100) {
          this.saveChangesDialog = false
          this.progress = 0

        }
      },
    resetProxyList() {
      if(Array.isArray(this.lineItems)) {
        this.proxyList = JSON.parse(JSON.stringify(this.lineItems))
      }
      this.listRenderKey += 1
    },
    addItem() {
      console.log('Thanks for everything! -Jóhann')
    },
    cancel() {
      this.resetProxyList()
      this.allowEdit(false)
    },
    compareTwoLineItems(item1, item2) {
      // Returns true if there is any difference in quantity, comment, unit price or discount
      if(item1.unitPrice.amount !== item2.unitPrice.amount) {
        return true
      }

      const comparisonList = ['quantity', 'comment', 'discountPercent']
      for (const i in comparisonList) {
        const property = comparisonList[i]
        if(item1[property] !== item2[property]) {
          return true
        }
      }
      return false
    },
    saveChanges() {
      if(!Array.isArray(this.proxyList) || !Array.isArray(this.lineItems)) {
        return null
      }

      this.saveChangesDialog = true
      this.allowEdit(false)

      // Tracking usage of saving changes to the line item
      this.$store.dispatch('trackEvent', { eventName: 'Line Item Edit Completed' })

      for(const itemKey in this.proxyList) {
        const currentItem = this.proxyList[itemKey]
        const originaltem = this.lineItems.find(i => i.uuid === currentItem.uuid)
        if (typeof originaltem === 'undefined') {
          // TODO:: create item
          this.addProgress()
        }
        else if (currentItem.deleted === true) {
          this.$store.dispatch('deleteLineItem', currentItem.line_item_id).then( () => {
            this.addProgress()
          })
        }
        else if(this.compareTwoLineItems(currentItem, originaltem)) {
          
          const payload = {
            uuid: currentItem.uuid,
            quantity: typeof currentItem.quantity === 'number' ? currentItem.quantity : parseFloat(currentItem.quantity),
            unitPrice: {
              amount: currentItem.unitPrice.amount,
              taxRate: currentItem.unitPrice.taxRate.uuid
            },
            discountPercent: currentItem.discountPercent,
            comment: currentItem.comment
          }
          this.$store.dispatch('updateLineItem', payload).then(() => {

            this.addProgress()
          }).catch(() => {

          })
        }
        else {
          this.addProgress()
        }
      }
    }
  }
}
</script>

<style>

.button-headers {
  margin-left: 5px;
  margin-right: 5px;
  text-transform: unset !important;
}

</style>
