<template>
  <v-card>
    <v-card-title>{{ orderTitle }}</v-card-title>
    <LineItems :line-items="order.lineItems" />
  </v-card>
</template>

<script>

import LineItems from '../lineItem/LineItemList'

export default {
    name: 'Order',
    props: {
        order: {
            type: Object,
            default: () => {}
        }
    },
    components: {
        LineItems
    },
    computed: {
        orderTitle() {

            if(this.order.order_id === null || this.order.order_id === undefined) {
                return this.$t('newOrder')
            }

            return this.order.order_id
        }
    }
}
</script>
